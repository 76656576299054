import { useRouter } from 'next/router';
import { useState } from 'react';
import ProductSmallTile from '../components/product-tile/product-small-tile';
import { server } from './../config';
import logo from './../public/logo.png';
import styles from './index.module.css';
import barnevognImg from './../assets/barnevogn.png';
import babycallImg from './../assets/babycall.png';
import hengevuggeImg from './../assets/hengvugge.png';
export default function Home() {

  const [frontProducts, setFrontProducts] = useState<any[] | null>(null);
  const [fetchedRandomProducts, setFetchedRandomProducts] = useState(false);

  const router = useRouter();
  if (!fetchedRandomProducts) {
    setFetchedRandomProducts(true);
    var funcs$ = [
      fetch(`${server}/api/getFrontProducts`),
    ]
    Promise.all(funcs$).then(async (response) => {
      setFetchedRandomProducts(true);
      setFrontProducts(await response[0].json());
    }).catch((error) => {
      console.error(error);
      setFetchedRandomProducts(true);
    });
  }

  return (
    <div className={styles.container} style={{ zIndex: 1001, initialLetter: 1 }}>
      <main style={{marginTop: '16px'}}>
      
        <section>
          <h2>Populære kategorier</h2>
          <article className={styles.categoryContainer} >
            <div className={styles.category} onClick={() => router.push('produkter/barnevogn')}><div>Barnevogner</div><div id="barnevogn"></div></div>
            <div className={styles.category} onClick={() => router.push('produkter/hengevugge')}><div>Hengevugger</div><div id="hengevugge"></div></div>
            <div className={styles.category} onClick={() => router.push('produkter/babycall')}><div>Babycall</div><div id="babycall"></div></div>
            <div className={styles.category} onClick={() => router.push('artikler/dette-trenger-du')}><div>Sjekkliste</div><div id="sjekkliste"></div></div>
          </article>
        </section>
        <br/>
        <article className={styles.indexArticle}>
            <img src={logo.src} style={{height: '42px', float: 'left', margin: '8px'}} alt="Hjerte" className="showInMobile"/>
            <h1 className={styles.title} style={{marginTop: 0}}>Babymoon.no - <small>Hvordan fungerer det?</small></h1>
          <p>
            Som førstegangsforeldre er det en stor jungel man skal navigere i for å finne frem til alt utstyret man trenger, og forsåvidt ikke trenger.
            Vi skal ikke fortelle dere hva dere trenger, men vi ønsker å gjøre det litt lettere å finne frem i jungelen av babyutstyr. Derfor jobber vi med å 
            samle en rekke produkter fra ulike leverandører og produsenter slik at dere kan finne det dere trenger på ett sted.
          </p>
          <p>
            Dersom flere nettbutikker tilbyr samme produkt, vil vi vise dere hvor dere kan finne det til den beste prisen.
          </p>
        </article>
        <br/>
        <div className="hideInMobile hideOnPad">
          <a href="https://pin.polarnopyret.no/t/t?a=1741761869&as=1907976616&t=2&tk=1"><img src="https://track.adtraction.com/t/t?a=1741761869&as=1907976616&t=1&tk=1&i=1" width="728" height="90" alt="PolarenOPyret" /></a>
        </div>   
        <div className="showInMobile">
          <a href="https://pin.polarnopyret.no/t/t?a=1314279075&as=1907976616&t=2&tk=1"><img src="https://track.adtraction.com/t/t?a=1314279075&as=1907976616&t=1&tk=1&i=1" width="468" height="60" alt="PolarenOPyret" /></a>
        </div>     
        <h2>Utvalgte produkter</h2>
        <section>
          {
            frontProducts?.map && frontProducts?.map(p =>
              <ProductSmallTile detailsPath={`/produkter/${p.brand}`} product={p} size='normal' key={p._id} />
            )
          }
        </section>
        <br/>
        <div className="hideInMobile hideOnPad">
          <a href="https://www.partner-ads.com/no/klikbanner.php?partnerid=30465&bannerid=78429" target="_blank" rel="nofollow noopener"> <img src="https://www.partner-ads.com/no/visbanner.php?partnerid=30465&bannerid=78429" alt="Min beste bok" /></a>
        </div>                    
      </main>
      <br />
      <div className="showInMobile" style={{ marginTop: '32px' }}>
        <a href="https://pin.polarnopyret.no/t/t?a=1314279072&as=1907976616&t=2&tk=1"><img src="https://track.adtraction.com/t/t?a=1314279072&as=1907976616&t=1&tk=1&i=1" width="300" height="250" alt="PolarenOPyret" /></a>
      </div>     
        <style jsx>{`
        #barnevogn {
          background-image: url('${barnevognImg.src}');
          background-position: top center;
          background-size: 92px;
        }
        #babycall {
          background-image: url('${babycallImg.src}');
          background-position: top center;
          background-size: 92px;
        }
        #hengevugge {
          background-image: url('${hengevuggeImg.src}');
          background-position: top center;
          background-size: 72px;
        }                         
      `}</style>        
    </div>
    
  );
}